import React from 'react'
import { createContext } from 'react'




const CurrentUserId = createContext(null)


export default CurrentUserId;
