



import React from 'react'

function Contactus() {
  return (
    <div>
      <h1>Contact page</h1>
    </div>
  )
}

export default Contactus
